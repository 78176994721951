:root {
  /* --backgroundColor: #282c35; */
  --backgroundColor: white;
  /* --lightBackgroundColor: #343a48;
  --redColor: #d0021b;
  --lightRedColor: #e36776;
  --yellowColor: #fff3c8;
  --greenColor: #a7ff91;
  --lightBlueColor: #aed1fb;
  --hoverBlueColor: #4898f6;
  --brightBlueColor: #348df5;
  --activeColor: #3b404b;
  --dimTextColor: #a7a9ae;
  --disabledTextColor: #57595c;
  --paragraphColor: #b4b4b4;
  --darkBlueColor: #2c313e;
  --disabledBlueColor: #1a477b; */
  --primary: #385BB1;
  --warning: #fc912e;
}

html,
body {
  background-color: var(--backgroundColor);
}

.App {
  /* color: white; */
  color: #282c35;
  font-family: 'Inter', sans-serif;
}

.App main {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.App .primary {
  flex: 1;
  background-color: var(--backgroundColor);
}

.App header nav {
  min-height: 60px;
}

.App header .navbar-brand {
  color: white;
  font-weight: 600;
  font-size: 0.9rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.App header .navbar-brand:hover {
  color: white;
}

.App header nav {
  justify-content: space-between;
}

.App header svg {
  margin-right: 10px;
}

.App header .nav-buttons> :not(:first-child) {
  margin-left: 5px;
}

/* FOOTER */

.App footer {
  background-color: var(--backgroundColor);
  padding: 30px 0;
  color: var(--paragraphColor);
  text-align: center;
  margin-top: 40px;
}

.App #footer-simple {
  position: absolute;
  bottom: 1.5rem;
  left: 0;
  right: 0;
  color: var(--paragraphColor);
}

.App footer a,
.App #footer-simple a {
  color: var(--lightBlueColor);
}

.App footer a:hover,
.App #footer-simple a:hover {
  text-decoration: none;
  opacity: 0.5;
}

/*everything around the data protection popover*/

.ds-button {
  background: white;
  border: none;
  color: #666;
  font-weight: 200;
}


.modal-content {
  position: absolute;
  /* height: 60%; */
}

.tab-content {
  height: 300px;
}

.tab-pane {
  height: 100%;
  overflow: auto;
}

.col-md-auto .nav-link {
  color: #242c41
}

.col-md-auto .nav-pills .nav-link.active {
  color: #385BB1;
  background: none;
  font-weight: 600;
  border: none;
  outline: none;
}


.mb-3 button {
  color: #242c41
}

.mb-3 .nav-link.active {
  color: #385BB1;
  font-weight: 600;
  background: none
}

.mb-3 button:hover {
  color: #18347a
}

.ds-button-close {
  color: #FF963C;
  outline: #fc912e;
}

/* LOBBY */

.App .lobby-form button {
  display: block;
  color: black;
  font-weight: 600;
  text-decoration: underline;
}

.App #lobby-left {
  flex-grow: 50;
  /* flex-basis: 320px; */
  background-color: var(--darkBlueColor);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.App #lobby-right {
  flex-grow: 50;
  /* flex-basis: 480px; */
}

.container-half {
  width: 100%;
  max-width: 500px;
  padding-right: 40px;
  padding-left: 40px;
}

@media screen and (max-width: 991px) {
  .container-half {
    max-width: 550px;
  }
}

.App .carousel-caption {
  position: relative;
  width: 80%;
  right: 0;
  bottom: 0;
  left: 0;
  padding-top: 0;
  padding-bottom: 0;
  text-align: left;
  font-weight: 200;
  font-size: 0.9rem;
}

.App .carousel-indicators {
  bottom: -2rem;
}

/* .App #sliderImage {
  flex-shrink: 0;
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-top: 0;
} */

.App .card-body {
  /* border: 2px solid sandybrown;
  border-radius: 12px; */
}

.App .card.show {
  border: 3px solid sandybrown;
  border-radius: 12px;
}

.App input#relTarget {
  outline: none;
  border: none;
  border-bottom: 1px solid #282c35;
  margin: 0 0.5rem;
  transform: translateY(-25%)
}

.App #lobby-left .container-half {
  margin-left: auto;
  /* margin-top: 50px; */
  padding-right: 60px;
}

.App #lobby-right .container-half {
  margin-right: auto;
  margin-top: 100px;
  padding-left: 60px;
}

.App #lobby .container-mobile {
  margin-top: 40px;
  padding: 0 30px;
  max-width: 400px;
}

.App .divider {
  border: 1px solid var(--activeColor);
  margin: 60px auto;
  width: 75%;
}

.App .touts> :not(:first-child) {
  /* margin-top: 40px; */
}

.App .touts h4 {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 140%;
}

.App .touts p {
  color: var(--paragraphColor);
  line-height: 140%;
  font-size: 1rem;
}

.App .lobby-form h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.App .lobby-form .form-group:not(:first-of-type) {
  margin-top: 2rem;
}

.App .lobby-form .error-message {
  height: 1rem;
  font-size: 0.9rem;
  color: var(--lightRedColor);
  font-weight: 500;
  text-align: center;
}

.App .lobby-form button[type='submit'] {
  width: 100%;
  background-color: var(--brightBlueColor);
  border: none;
  margin-top: 1rem;
  padding: 12px 15px;
}

.App .lobby-form button[type='submit']:hover {
  background-color: var(--hoverBlueColor);
}

.App .lobby-form button[type='submit']:disabled {
  background-color: var(--disabledBlueColor);
}

.App .lobby-form button.inline {
  background: none;
  border: none;
  padding: 0;
  font-weight: 500;
  display: inline-block;
  color: var(--lightBlueColor);
  width: auto;
}

.App .lobby-form .switcher {
  text-align: center;
  margin-top: 1.5rem;
  font-size: 0.9rem;
}

.App .lobby-form button.inline:hover {
  opacity: 0.5;
}

/* GAME */

.App #game .container-loading {
  margin-top: 80px;
  text-align: center;
}

.App #game .container {
  text-align: center;
  margin-top: 20px;
}

.App #match-title {
  margin-bottom: 0px;
}

.App .warning {
  color: var(--lightRedColor);
  font-weight: 500;
}

.App #buzzer {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.App #buzzer>button {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-color: var(--brightBlueColor);
  color: red(109, 106, 106);
  border: none;
  font-weight: 700;
  font-size: 2rem;
  user-select: none;
}

.App #buzzer>button:hover {
  background-color: var(--hoverBlueColor);
}

.App #buzzer>button:active {
  background-color: var(--darkBlueColor);
}

.App #buzzer>button:disabled {
  background-color: var(--lightBackgroundColor);
}

@media screen and (max-width: 767px) {
  .App #buzzer>button {
    max-width: 200px;
    max-height: 200px;
  }
}

.App .settings {
  margin-top: 30px;
}

.App .settings>.button-container:not(:first-child) {
  margin-top: 10px;
}

.App .settings button:hover:disabled {
  background-color: none;
}

.App .settings .divider {
  margin: 40px auto;
  width: 50%;
}

.App #reset {
  margin: 30px 0;
}



/* .App .queue {
  margin-top: 40px;
}

.App .queue p {
  margin-bottom: 0;
}

.App .queue ul {
  padding: 0;
  list-style-type: none;
}

.App .queue li {
  font-size: 1.5rem;
} */

/* .App .queue li .player-sign {
  display: inline-block;
  min-width: 150px;
  padding: 5px 15px;
  border-radius: 4px;
  transition: all ease-in-out 0.1s;
}

.App .queue li .name {
  font-weight: 600;
}

.App .queue li.resettable .player-sign {
  cursor: pointer;
}

.App .queue li.resettable .player-sign:hover {
  background-color: var(--lightBackgroundColor);
}

.App .queue .disconnected {
  margin-left: 5px;
  color: var(--redColor);
  opacity: 1;
}

.App .mini {
  font-size: 1rem;
}

.App .tiny {
  font-size: 0.8rem;
}

.App .bold {
  font-weight: 600;
}

.App .dim {
  opacity: 0.5;
} */